
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { phone } from '@/utils/validate'

@Component({
  name: 'AddBase'
})
export default class extends Vue {
  @Prop() readonly addShow!: boolean
  @Prop() readonly id!: string

  private info = {
    // 基础信息
    projectId: '',
    teamName: '',
    teamLeader: '',
    teamLeaderPhone: '',
    supplierId: '',
    cooperationModel: ''
  }

  private supplierData = []
  private submitShow = false

  private rules= {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    teamName: [
      { required: true, message: '请输入班组名称', trigger: 'change' }
    ],
    teamLeader: [
      { required: true, message: '请输入班组负责人', trigger: 'change' }
    ],
    teamLeaderPhone: [
      { validator: phone },
      { required: true, message: '请输入负责人电话', trigger: 'change' }
    ],
    supplierId: [
      { required: true, message: '请选择所属供应商', trigger: 'change' }
    ]
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  @Watch('id')
  isTrue (val: string) {
    val && this.getDetail()
  }

  created () {
    this.loadSupplierType() // 加载供应商
  }

  loadSupplierType () {
    this.$axios.get(this.$apis.material.selectYhSupplierByPage).then(res => {
      this.supplierData = res.list
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.laborManage.selectYhTeamByTeamId, { teamId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    })
  }

  saveForm () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const urls = this.id ? this.$apis.laborManage.updateYhTeam : this.$apis.laborManage.insertYhTeam
        this.$axios.post(urls, this.info).then(() => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  handleClose () {
    this.$emit('update:addShow', false)
    this.$emit('update:id', '')
    this.info = {
    // 基础信息
      projectId: '',
      teamName: '',
      teamLeader: '',
      teamLeaderPhone: '',
      supplierId: '',
      cooperationModel: ''
    };
    (this.$refs.formData as ElForm).resetFields()
  }
}
