
import { Component, Vue } from 'vue-property-decorator'
import Add from './Add.vue'
import Details from './Details.vue'

@Component({
  name: 'laborManageListTeams',
  components: { Add, Details }
})

export default class extends Vue {
  private info = {
    projectId: '',
    teamName: ''
  }

  private addShow = false
  private detailsShow = false
  private id = ''

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData = [
    {
      teamId: '202309211724039812102',
      teamName: '测试班组1111',
      projectId: '20230411085100387502',
      projectName: '测试项目1',
      teamLeader: '张三',
      teamLeaderPhone: '13026308011',
      supplierId: '20230411092425475418',
      supplierName: '1',
      cooperationModel: '1',
      status: '1'
    }
  ]

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadData()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios
      .get(
        this.$apis.laborManage.selectYhTeamByPage,
        {
          ...this.info,
          page: this.page,
          size: this.size
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 新增
  onAdd () {
    console.log('新增')
    // this.$router.push({ name: 'addTeams' })
    this.addShow = true
  }

  // 编辑
  onEdit (id: string) {
    this.id = id
    this.addShow = true
  }

  // 查看
  onDetail (id: string) {
    this.id = id
    this.detailsShow = true
  }

  // 启用/禁用班组
  onForbidden (teamId: string, status: any) {
    let submitStatus = ''
    let description = ''
    if (status === '1') {
      submitStatus = '2'
      description = '禁用'
    } else {
      submitStatus = '1'
      description = '启用'
    }
    this.$confirm(`确认${description}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.laborManage.forbiddenYhTeam, { teamId, status: submitStatus }).then(() => {
          this.$message.success('操作成功')
          this.loadData()
        }).finally(() => {
          this.loading = false
        })
      })
  }

  // 删除
  onDelete (teamId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.laborManage.deleteDataYhTeam, { teamId }).then(() => {
          this.$message.success('删除成功')
          this.onSearch()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
