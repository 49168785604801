
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component({
  name: 'Detailteams',
  filters: {
    cooperationModel: function (value: any) {
      if (value) {
        if (value === '1') {
          return '包工模式'
        } else {
          return '点工模式'
        }
      }
    }
  }
})
export default class extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly detailsShow!: boolean

  private loading = false

  private info = {
    // 基础信息
    projectName: '',
    teamName: '',
    teamLeader: '',
    teamLeaderPhone: '',
    supplierName: '',
    cooperationModel: ''
  }

  @Watch('detailsShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    // this.loading = true
    this.$axios.get(this.$apis.laborManage.selectYhTeamByTeamId, { teamId: this.id }).then(res => {
      if (res) {
        this.info = res
      }
    }).finally(() => {
      // this.loading = false
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:detailsShow', false)
    this.info = {
      // 基础信息
      projectName: '',
      teamName: '',
      teamLeader: '',
      teamLeaderPhone: '',
      supplierName: '',
      cooperationModel: ''
    }
  }
}
